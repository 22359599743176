<template>
  <footer class="main-footer">
   <!-- Services block-->
   <div class="text-dark-700 py-6">
     <div class="container">
       <div class="row">
         <div class="col-lg-4 service-column">
           <!-- <svg class="svg-icon service-icon">
             <use xlink:href="#delivery-time-1"> </use>
           </svg> -->
           <img class="svg-icon service-icon" src="/assets/media/camion.gif" alt="">
           <div class="service-text">
             <h6 class="text-uppercase">Envios </h6>
             <p class="text-muted fw-light text-sm mb-0">Envios</p>
           </div>
         </div>
         <div class="col-lg-4 service-column">
           <img class="svg-icon service-icon" src="/assets/media/euro.gif" alt="">
           <div class="service-text">
             <h6 class="text-uppercase">Mercado Pago</h6>
             <p class="text-muted fw-light text-sm mb-0">Nuestro medio de pago</p>
           </div>
         </div>
         <div class="col-lg-4 service-column">
           <img class="svg-icon service-icon" src="/assets/media/tailandes.gif" alt="">
           <div class="service-text">
             <h6 class="text-uppercase">2954466495</h6>
             <p class="text-muted fw-light text-sm mb-0">Telefono</p>
           </div>
         </div>
       </div>
     </div>
   </div>
   <!-- Main block - menus, subscribe form-->
   <div class="py-6 text-muted" style="    background-color: #000000 !important;"> 
     <div class="container">
       <div class="row">
         <div class="col-lg-4 mb-5 mb-lg-0" >
           <div class="fw-bold text-uppercase text-lg text-white mb-3">𝑩𝒓𝒆𝒂𝒌 𝒑𝒐𝒊𝒏𝒕<span style="color:white">.</span></div>
           <p class="text-white">Buscanos en nuestro instagram.</p>
           
           <ul class="list-inline">
             <li class="list-inline-item">
               <a class="text-muted text-primary-hover" href="https://www.instagram.com/parati9911/" target="_blank" title="Facebook">
                 <img src="/assets/media/instagram.png" style="width:20px">
               </a>
             </li>
            
           </ul>
         </div>
         <!--
              <div class="col-lg-2 col-md-6 mb-5 mb-lg-0">
           <h6 class="text-uppercase text-white mb-3">Categorías</h6>
           <ul class="list-unstyled">
             <li> <a class="text-white" href="#">For Women</a></li>
             <li> <a class="text-white" href="#">For Men</a></li>
             <li> <a class="text-white" href="#">Stores</a></li>
             <li> <a class="text-white" href="#">Our Blog</a></li>
             <li> <a class="text-white" href="#">Shop</a></li>
           </ul>
         </div>
         -->
      
          <div class="col-lg-2 col-md-6 mb-5 mb-lg-0" >
           <h6 class="text-uppercase text-white mb-3">Guía</h6>
           <ul class="list-unstyled">
   
             <li> <router-link to="/"> <a class="text-white" >Inicio </a> </router-link></li>
             <li> <router-link  :to="{name: 'shop'}"><a class="text-white" >Productos </a> </router-link></li>
             <li> <router-link to="/checkout" > <a class="text-white" >Pagar </a> </router-link></li>
             <li> <router-link  to="/cuenta/direcciones">  <a class="text-white" >Direcciones </a></router-link></li>
             <li> <router-link to="/cart"> <a class="text-white" >Carrito </a> </router-link></li>
             <li> <router-link  to="/cuenta/venta"><a class="text-white" >Mis Compras </a> </router-link> </li>
          
           </ul>
         </div>
       
         <div class="col-lg-2 col-md-6 mb-5 mb-lg-0" >
            <h6 class="text-uppercase text-white mb-3">Sucursales <img src="../assets/gps.png" > </h6>
            <p> Avellaneda 648, Gral. Acha, La Pampa</p>
            <p> Victoriano Rodríguez 738, Gral. Acha, La Pampa</p>
         </div>
    <!--
           
         <div class="col-lg-4">
           <h6 class="text-uppercase text-white mb-3">Boletín informatico</h6>
           <p class="mb-3 text-white"> Lorem ipsum dolor sit amet, consectetur adipisicing elit. At itaque temporibus.</p>
           <form action="#" id="newsletter-form">
             <div class="input-group mb-3">
               <input class="form-control bg-transparent border-secondary border-end-0" type="email" placeholder="Your Email Address" aria-label="Your Email Address" style="    border-color: #ffffff !important;color:white !important">
               <div class="input-group-append" >
                 <button class="btn border-start-0" type="submit" style="    border-color: #ffffff !important;">
                   <img src="/assets/media/busqueda.png" style="width: 18px" alt="">
                 </button>
               </div>
             </div>
           </form>
         </div>
    -->
  
       </div>
     </div>
   </div>
   <!-- Copyright section of the footer-->
   <div class="py-4 fw-light text-gray-300" style="background-color: #101010 !important;">
     <div class="container">
       <div class="row align-items-center">
         <div class="col-md-6 text-center text-md-start">
           <p class="mb-md-0">&copy; {{year}} Para Ti.  Creada por nosotros <a href="https://puchdeveloping.com/" style="text-decoration: none; font-size:20px; color: #ffffff;">Puch</a>.</p>
         </div>
         <div class="col-md-6">
           <ul class="list-inline mb-0 mt-2 mt-md-0 text-center text-md-end">

            
           </ul>
         </div>
       </div>
     </div>
   </div>
 </footer>
</template>

<script>
export default {
name: 'Footer',
data() {
 return {
   year: new Date().getFullYear(),
 }
},
}
</script>
