import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {BootstrapVue , IconsPluguin } from 'bootstrap-vue'

import Notifications from 'vue-notification'
import VueSocketIO from 'vue-socket.io'
import SocketIO from 'socket.io-client'
 
import 'bootstrap-vue/dist/bootstrap-vue.css'

const socketConnection = SocketIO('https://api.para-ti.com.ar');
//const socketConnection = SocketIO('https://api.para-ti.com.ar');

Vue.config.productionTip = false
Vue.prototype.$envio = 0;
Vue.prototype.$paises = ['Argentina','Chile','Peru','Bolivia','Colombia'],
Vue.prototype.$url = 'https://api.para-ti.com.ar/api/';
//Vue.prototype.$url = 'https://api.para-ti.com.ar/api/';
Vue.prototype.$token = localStorage.getItem('token');
Vue.use(BootstrapVue)
Vue.use(new VueSocketIO({
  debug: true,
  connection: socketConnection,
  vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
  },

}))

 
Vue.use(Notifications)


new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
