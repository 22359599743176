<template>
  <div class="home" style="background:#EBEDE7 !important;">
    <section>
 
      <div class="container-fluid"  style="background: #000000; " >
        <div class="row"  v-for="item in img_inicio" v-if="item.estado_inicio" >
          
          <div class="col-lg-12 dark-overlay split-screen-column align-items-center align-items-lg-end" style="width:100% ;  object-fit: cover;  padding: 0px; "> 
           <img :src="$url+'/obtener_imagenInicio/'+item.banner_descuento" alt="..."  style="width:100% ; height: 100vh; object-fit: cover; margin: 0px; padding: 0px; background-color: #d2cecb;">
         
            <!--acaaaa-->
            <div class="overlay-content py-6 mt-6 px-lg-5 mt-lg-0 mb-lg-5" style="text-align: left; position:absolute ; z-index: 999;">
            
            <div class="py-6 px-lg-5 mb-lg-5">
            <h1 class="display-3 mb-4 text-white fw-bold split-screen-heading letter-spacing-1" >BREACK POINT</h1>
            <p class="lead mb-4  text-white fw-bold split-screen-heading letter-spacing-1">{{ item.titulo }}  </p>
            <p> 
              <routerLink class="btn btn-light mx-1 mb-1" to="/cuenta/venta">Mis compras   </routerLink>
              <routerLink class="btn btn-dark mx-1 mb-1" to="/cuenta/direcciones">Direcciones  </routerLink> 
            </p>
          </div>
          
          </div>
          
        </div>
        <!--
            <div class="col-lg-6 split-screen-column align-items-center align-items-lg-end" style="background-image: url(https://d19m59y37dris4.cloudfront.net/sell/2-0/img/photo/ian-dooley-347962-unsplash.jpg); background-color: #e5b6b4;"> 
            <div class="py-6 px-lg-5 mb-lg-5">
              <h1 class="display-3 mb-4 text-white fw-bold split-screen-heading letter-spacing-1">Ladies              </h1>
              <p class="lead mb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
              <p> <a class="btn btn-light mx-1 mb-1" href="category.html">View collection      </a><a class="btn btn-outline-light mx-1 mb-1" href="category.html">See more                              </a></p>
            </div>
          </div>
        -->
        </div>
      </div>
        <!--Banners--> 
        <div class="container-fluid" style="width: 95%; ">
            <div class="row" style=""  v-for="item in banners"> 
         
                <img class="img-fluid" style="height: 200px; width: 100%; border-radius: 55px; margin-top: 15px; object-fit:cover !important;" :src="$url+'/obtener_portada_banner/'+item.banner_descuento" alt="..." >
  
            </div>
           </div>
     
 
    </section>
    <section class="" >
       
      <div class="container" style="margin-top: 2rem !important;">
        <div class="row">
          <div class="col-xl-8 mx-auto text-center mb-5">
                <!-- Breadcrumbs -->
                <ol class="breadcrumb justify-content-center">
                    <li class="breadcrumb-item">
                        
                        <router-link to="/">Inicio</router-link>
                    </li>
                  
                </ol>
            <h2 class="text-uppercase">Nuevos productos</h2>
            <p class="lead text-muted">Algunos de Nuestros ultimos ingresos</p>
          </div>
        </div>
        <div class="row" v-if="load_nuevos_productos">
            <div class="col-12 text-center">
              <img src="/assets/media/idea.gif" alt="" style="width: 80px">
            </div>
        </div>
        <div class="row" v-if="!load_nuevos_productos" >
          <!-- product-->
          <div class="col-lg-3 col-md-4 col-12" v-for="item in nuevos_productos" id="productito" style="border-radius:5px;">
            <router-link  :to="{name:'show-producto', params:{slug: item.slug}}" >
              <div class="product">
              <div class="product-image" >
                <div class="ribbon ribbon-danger" v-if="item.descuento">Oferta</div>
                <img class="img-fluid"  style="height: 280px; width: 100%; object-fit:cover !important;" :src="$url+'/obtener_portada_producto/'+item.portada" alt="product"/>
                <div class="product-hover-overlay">
                  <a class="product-hover-overlay-link" ></a>
                </div>
              </div>
              <div class="py-2">
                <p class="text-muted text-sm mb-1">{{item.categoria}}</p>
                <h3 class="h6 text-uppercase mb-1">
                  <a class="text-dark" >{{item.titulo}}</a>
                </h3>
                <span v-if="!item.descuento" class="text-muted">{{convertCurrency(item.precio)}}</span>
                <span v-if="item.descuento" class="text-muted">{{convertCurrency(item.precio_descuento)}} - <del style="color:red; font-weight:600; ">{{convertCurrency(item.precio)}}</del></span>
              </div>
            </div>
            </router-link>
         
          </div>
          <!-- /product-->
         
        </div>
      </div>
    </section>
    <section class="py-6 bg-gray-100" style="background: #EBEDE7 !important;"  >
      <div class="container" >
        <div class="row">
          <div class="col-xl-8 mx-auto text-center mb-5">
            <h2></h2>
            <p class="lead text-muted"></p>
          </div>
        </div>
        <div class="row"  ><!---->
          
          <!--Primer imagen cuadricular-->
          <div class="col-md-6 d-flex align-items-md-stretch mb-4 mb-md-0" v-for="item in img_inicio" v-if="item.estado_inicio_uno">
            <!--<div class="d-flex align-items-center text-white dark-overlay w-100 text-center py-6 py-md-0" style="height: 120vh; background: center center url(../assets/img/pibe6.jpg) no-repeat; background-size: cover;"></div>-->
           <div class="card border-0 text-white text-center mb-4 dark-overlay" style="height: 100%">
            <img :src="$url+'/obtener_imagenInicio/'+item.banner_descuento" alt="..."  class="card-img" style="width:100% ; height: 100%; object-fit: cover; ">
            
           
          
           </div>
          
          
          </div>
          
           <!--Primer imagen cuadricular-->

          <div class="col-md-6">
            <div class="card border-0 text-white text-center mb-4 dark-overlay" v-for="item in img_inicio" v-if="item.estado_inicio_dos"> 
              <img :src="$url+'/obtener_imagenInicio/'+item.banner_descuento" alt="..."  class="card-img" style="height: 58.3vh; object-fit:cover; ">
      
              <div class="card-img-overlay d-flex align-items-center">
                <div class="text-center w-100 overlay-content">
                  <h2 class="display-4 fw-bold text-uppercase text-center mb-0">                                               </h2>
                </div>
              </div>
            </div>
<!--<img class="card-img" style="height: 58.3vh; object-fit:cover; "  src="../../public/assets/img/zapatillas2.jpg" alt="Accessories">-->           
            <div class="card border-0 text-white text-center  dark-overlay" v-for="item in img_inicio" v-if="item.estado_inicio_tres"> 
              <img :src="$url+'/obtener_imagenInicio/'+item.banner_descuento" alt="..." class="card-img" style="height: 58.3vh; object-fit:cover; ">
            
              <div class="card-img-overlay d-flex align-items-center">
                <div class="text-center w-100 overlay-content">
                  <h2 class="display-4 fw-bold text-uppercase text-center mb-0">                                           </h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    


<!--

-->

    <section class="mh-full-screen dark-overlay py-7 d-flex align-items-center justify-content-center" v-for="item in img_inicio" v-if="item.estado_inicio_sub">
      <img :src="$url+'/obtener_imagenInicio/'+item.banner_descuento" alt="..." class="bg-image" style="object-fit:cover; ">
      
      <!--<img class="bg-image" src="../assets/skate1.jpg" alt="">-->
      <div class="overlay-content w-100">
        <div class="container">
          <div class="row">
            <div class="col-xl-6 text-white">
              <h6 class="text-uppercase text-white letter-spacing-5 mb-3"> Para ti</h6>
              <h1 v-if="item.titulo" class="display-3 fw-bold text-shadow mb-5">{{ item.titulo }}</h1>
              <p class="text-lg"></p>
            </div>
          </div>
        </div>
      </div>
    </section>

                     <!-- /whatsapp boton... los estilos css los ihce yo y estan la carpeta tienda/public/assets/css/nouislider.css con la clase "btn-whatsapp"-->
                     <a href="https://api.whatsapp.com/send?phone=5492954466495&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n%20%20%20" class="float" target="_blank">
     <img src="/assets/media/whatsapp.png" alt="">
</a>












  </div>

</template>
<style>
#productito:hover {
  color: #ffffff !important;
  border-color: #fff #fff #343a40 !important;
  background: #ffffff !important;
}
</style>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue';
import axios from 'axios';
import currency_formatter from 'currency-formatter';
import { init_carousel } from '../../public/assets/js/theme.d7b4a888';
 
export default {
  name: 'HomeView',
  data() {
    return {
        nuevos_productos : [],
        load_nuevos_productos: true,
        banners:[],
        img_inicio:[],
        get itemForBanner(){
           return this.banners.slice(
               (this.currentPage-1) * this.perPage, this.currentPage* this.perPage
           )
        },
        get itemForimgInicio(){
            return this.img_inicio.slice(
                (this.currentPage-1) * this.perPage, this.currentPage* this.perPage
            )
        },
    }
  },
  methods: {
    init_nuevos_productos(){
      this.load_nuevos_productos = true;
      axios.get(this.$url+'/obtener_nuevos_productos',{
          headers: {
              'Content-Type': 'application/json'
          }
      }).then((result)=>{
        this.nuevos_productos = result.data;
        this.load_nuevos_productos = false;
        console.log(this.nuevos_productos);
      });
    },
    convertCurrency(number){
        return currency_formatter.format(number, { code: 'USD' });
    },
         mostrar_banners(){
                
            axios.get(this.$url+'/obtener_banners_shop',{
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then((result)=>{
                this.banners = result.data;
                console.log(this.itemForBanner);
                

               
            });
            },
            imgInicio(){
                
                axios.get(this.$url+'/obtener_img_ini',{
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }).then((result)=>{
                    this.img_inicio = result.data;
                    console.log(this.itemForimgInicio);
                    
    
                   
                });
            },
  },
  components: {
    HelloWorld
  },
  beforeMount() {
    this.init_nuevos_productos();
    init_carousel.init();
    this.mostrar_banners();
    this.imgInicio();
   console.log(this.itemForimgInicio)
  },
}
</script>
